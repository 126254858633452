import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    type: CardType,
    size?: StickerSize,
    className?: string,
    labelClassName?: string,
    buttonClassName?: string
    jpTextClassName?: string,
}

const Sticker = ({
    size,
    type,
    className,
    labelClassName,
    buttonClassName,
    jpTextClassName,
}: Props) => {
    return (
        <div
            className={twMerge(`
                w-full flex flex-col
                ${
                    size === 'wise' ? 'max-w-[240px]' : 'max-w-[200px]'
                }
                ${
                    size === 'small' ?
                        `
                            md:max-w-[235px]
                            lg:max-w-[270px]
                        ` :
                        `
                            md:max-w-[300px]
                            lg:max-w-[350px]
                        `
                }
            `, className)}>
            {
                type === 'sales' &&
                    <div
                        className={twMerge(`
                            w-fit self-end text-[13px] text-[#3866AB] leading-[1.2]
                            ${
                                size === 'small' ?
                                    `
                                        md:text-[14px]
                                        lg:text-[16px]
                                    ` :
                                    `
                                        md:text-[16px]
                                        lg:text-[20px]
                                    `
                            }
                        `, labelClassName)}>
                        <div className="font-bold leading-[1.2] mb-1">
                            <span className="font-bold leading-[1.2]">保険営業</span>
                            <span className="font-normal leading-[1.2]">向け</span>
                        </div>
                    </div>
            }
            <h2
                className={   
                    twMerge(
                        `
                            w-full text-[6.266vw] text-white leading-[1.4] text-center whitespace-nowrap
                            sm:text-[25px]
                            ${
                                size === 'wise' ?
                                    `
                                        px-12
                                        md:px-14
                                        lg:px-20
                                    ` :
                                    `
                                        px-[16px]
                                        md:px-[20px]
                                        lg:px-[24px]
                                    `
                            }
                            ${
                                size === 'small' ?
                                    `
                                        md:text-[28px]
                                        lg:text-[32px]
                                    `:
                                    `
                                        md:text-[31px]
                                        lg:text-[37px]
                                    `
                            }
                            ${
                                type === 'navi' ? 'bg-[#06B6D4]' : 'bg-[#3866AB]'
                            }
                        `,
                        buttonClassName
                    )
                }>
                {
                    type === 'navi' ?
                        <span className="font-bold leading-[1.4]">NAVI&nbsp;</span> :
                        <span className="font-bold leading-[1.4]">SALES&nbsp;</span>
                        
                }
                <span
                    className={twMerge(`
                        font-normal text-[4.01vw] leading-[1.4]
                        sm:text-[16px]
                        ${
                            size === 'small' ?
                                `            
                                    md:text-[19px]
                                    lg:text-[22px]
                                ` :
                                `
                                    md:text-[21px]
                                    lg:text-[26px]
                                `
                        }
                    `, jpTextClassName)}>
                    プラン
                </span>
            </h2>
        </div>
    );
}

export default Sticker;